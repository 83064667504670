const config = {
    STRIPE_PUSH_KEY: 'pk_test_51LfxOwD3RQAEZ8fAnoekqcjPXBRxkPPUGVh2RxVmiht43eHopbLNtDzGdhQU5NkxhESuUSm3aTpqBvy6kUmB1Y8p00aUtGDYzm',
    s3: {
      REGION: process.env.REACT_APP_REGION,
      BUCKET: process.env.REACT_APP_BUCKET,
    },
    apiGateway: {
      REGION: process.env.REACT_APP_REGION,
      URL: process.env.REACT_APP_API_URL,
    },
    cognito: {
      REGION: process.env.REACT_APP_REGION,
      USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
      APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
      IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    },
  };
  
export default config;