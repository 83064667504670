import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import LoaderButton from "../components/LoaderButton";
import { Auth } from "aws-amplify";
import { onError } from "../lib/errorLib";
import { useFormFields } from "../lib/hookLib";
import "./Login.css";

export default function ForgotLogin() {

    const [fields, handleFieldChange] = useFormFields({
        email: "",
    });

    const { email } = fields;
    const [isLoading, setIsLoading] = useState(false);

    function validateForm() {
        return email.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            const result = await Auth.forgotPassword(email, {
                
            });
            console.log('result', result);
        } catch (e) {
            onError(e);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="ForgotLogin">
            <Form onSubmit={handleSubmit}>
                <Form.Group size="lg" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        autoFocus
                        type="email"
                        value={email}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <LoaderButton
                    block="true"
                    size="lg"
                    type="submit"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                >
                    Login
                </LoaderButton>
            </Form>
        </div>
    );
}