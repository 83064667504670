import React from 'react';
import createButton from './create-button';
import createSvgIcon from './createSvgIcon';


function Icon({ width, height, color }) {
    return (< svg width={width} height={height} viewBox="0 0 25 25" style={{ transform: 'scale(1)' }} version="1.1" xmlns="http://www.w3.org/2000/svg" >
        <g stroke="#ffffff" strokeWidth="1"  fillRule="inherit">
            <path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z" fill="#ffffff" fillRule="nonzero"></path>
        </g>
    </svg >);
}

const config = {
    activeStyle: { background: '#000000' },
    icon: createSvgIcon(Icon),
    style: { background: '#000000', color: '#FFFFFF' },
    text: 'Log in with TikTok',
};

export const TikTokLoginButton = createButton(config);
