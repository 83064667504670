import React, { useState, useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "aws-amplify";

import { AppContext } from "./lib/appContext";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useNavigate } from "react-router-dom";

import Routes from "./Routes";
import "./App.css";

function App() {

  const nav = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      setIsAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }
    setIsAuthenticating(false);
  }
  async function handleLogout() {
    await Auth.signOut();
    setIsAuthenticated(false);
    nav('/login');
  }

  return (
    !isAuthenticating && (
      <div className="App container py-3">
        <Navbar collapseOnSelect bg="light" expand="md" className="mb-3 px-3">
          <LinkContainer to="/">
            <Navbar.Brand className="font-weight-bold text-muted">
              Supporter Gift
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav activeKey={window.location.pathname}>
              {isAuthenticated ? (
                <>
                  <LinkContainer to="/settings">
                    <Nav.Link>Settings</Nav.Link>
                  </LinkContainer>
                  <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                </>
              ) : (
                <>
                  <LinkContainer to="/signup">
                    <Nav.Link>Signup</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/login">
                    <Nav.Link>Login</Nav.Link>
                  </LinkContainer>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <AppContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
          <Routes />
        </AppContext.Provider>
        <footer className="py-3 my-4">
    <ul className="nav justify-content-center border-top pb-3 mb-3">
      <li className="nav-item"><a href="/privacy" className="nav-link px-2">Privacy</a></li>
      <li className="nav-item"><a href="/tos" className="nav-link px-2">TOS</a></li>
      <li className="nav-item absolute right"><span className="nav-link px-2 text-muted">© 2022 Supporter Gift</span></li>
    </ul>
    {/* <p className="text-center text-muted"></p> */}
  </footer>
      </div>
    )
  );
}

export default App;