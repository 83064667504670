import { useEffect, useState } from 'react';
import {
    Table,
    Image,
} from 'react-bootstrap';
import './SupporterRank.css'

export const SupporterRank = () => {

    const [supports, setSupports] = useState([]);

    useEffect(() => {

        setTimeout(() => {
            const _supports = [];
            _supports.push({
                src: 'https://revgineer.com/wp-content/uploads/2019/12/ThisPersonDoesNotExist_fail2-768x811.jpg',
                name: 'Jack23',
                amount: 23.75,
                timestamp: 'Sept 12, 2022'
            });
            setSupports(_supports);
        }, 150)


    }, []);

    const supporterRows = supports.map((support, index) => (<tr key={index}>
        <td>{index+1}</td>
        <td><Image className="avatar" height={50} width={50} src={support.src} /></td>
        <td>{support.name}</td>
        <td>${support.amount}</td>
        <td>{support.timestamp}</td>
    </tr>))

    return (
        <Table id="supporter-list" size={'sm'} striped={true} bordered={true} hover={true}>
            <thead>
                <tr>
                    <th>Rank</th>
                    <th>Photo</th>
                    <th>Name</th>
                    <th>Amount</th>
                    <th>Date</th>
                </tr>
            </thead>
            <tbody>
                {supporterRows}
            </tbody>
        </Table>
    );
}