import { useCallback, useState, useEffect } from 'react';
import { Accordion, Badge, Button } from 'react-bootstrap';
import { PersonBadgeFill } from 'react-bootstrap-icons';
import {
    LoginSocialGoogle,
    LoginSocialInstagram,
} from 'reactjs-social-login';

import {
    LoginSocialTwitch,
    LoginSocialTiktok,
    LoginSocialTwitter
} from '../components/social';

import {
    TikTokLoginButton,
    TwitchLoginButton
} from '../components/social/buttons';

import {
    GoogleLoginButton,
    GithubLoginButton,
    InstagramLoginButton,
    TwitterLoginButton,
} from 'react-social-login-buttons';

import "./SocialAccounts.css";



export const SocialAccount = () => {

    const REDIRECT_URI = 'https://supporter.gift/dashboard';
    const LOCAL_REDIRECT = 'http://localhost:3000/dashboard';


    const REDIRECT = window.location.host === 'supporter.gift' ? REDIRECT_URI : LOCAL_REDIRECT;

    const [account, setAccount] = useState(null);
    const [provider, setProvider] = useState('');
    const [profile, setProfile] = useState();
    const [socialAccounts, setSocialAccounts] = useState([]);

    const onLoginStart = useCallback(() => {
        alert('login start');
    }, []);

    const onLogoutSuccess = useCallback(() => {
        setProfile(null);
        setProvider('');
        alert('logout success');
    }, [])

    const onLogout = useCallback(() => { }, []);

    useEffect(() => {

        if (!socialAccounts.length)
            setSocialAccounts([{
                name: "TikTok",
                username: null,
                verified: false,
                followers: 0
            }, {
                name: "Twitch",
                username: null,
                verified: false,
                followers: 0
            }, {
                name: "Youtube",
                username: null,
                verified: false,
                followers: 0
            }, {
                name: "Instagram",
                username: null,
                verified: false,
                followers: 0
            }, {
                name: "Twitter",
                username: null,
                verified: false,
                followers: 0
            }]);
    }, []);


    const updateSocial = ({ provider, username, followers }) => {
        
    }

    const connectAccount = (name, username, followers = 0) => {
        const index = socialAccounts.findIndex(x => x.name.toLowerCase() == name);
        if (index > -1) {
            socialAccounts[index].username = username;
            socialAccounts[index].verified = true;
            socialAccounts[index].followers = followers;
            console.log(socialAccounts);
            setSocialAccounts([...socialAccounts]);
        }
    }


    const displaySocial = (name) => {
        switch (name) {
            case 'TikTok':
                return (
                    <LoginSocialTiktok
                        client_id={process.env.REACT_APP_TIKTOK_ID || 'awqjnjw0yydkyoru'}
                        scope={'name email'}
                        redirect_uri={REDIRECT}
                        onLoginStart={onLoginStart}
                        onResolve={({ provider, data }) => {
                            console.log('profile', data);
                            connectAccount(provider, profile.preferred_username)
                        }}
                        onReject={err => {
                            console.log(err);
                        }}
                    >
                        <TikTokLoginButton />
                    </LoginSocialTiktok>
                );
            case 'Twitter':
                return (
                    <LoginSocialTwitter
                        client_id={process.env.REACT_APP_TWITTER_V2_APP_KEY || 'V1doSjFQZ2IyaGtPNVJlVEZKb2k6MTpjaQ'}
                        // client_secret={process.env.REACT_APP_TWITTER_V2_APP_SECRET || ''}
                        redirect_uri={REDIRECT}
                        onLoginStart={onLoginStart}
                        onLogoutSuccess={onLogoutSuccess}
                        onResolve={({ provider, data }) => {
                            console.log('profile', data);
                            connectAccount(provider, data.username || data.preferred_username)
                        }}
                        onReject={(err) => {
                            console.log(err);
                        }}
                    >
                        <TwitterLoginButton />
                    </LoginSocialTwitter>
                );
            case 'Twitch':
                return (
                    <LoginSocialTwitch
                        client_id={process.env.REACT_APP_GITHUB_APP_ID || '6x9nog978crv9c4ypeouiyjupwdtz4'}
                        client_secret={process.env.REACT_APP_GITHUB_APP_SECRET || 'gqg10r0vm6kvdhgpjlt45v0lydhtz0'}
                        redirect_uri={REDIRECT}
                        onLoginStart={onLoginStart}
                        onLogoutSuccess={onLogoutSuccess}
                        onResolve={({ provider, data }) => {
                            console.log('profile', data);
                            connectAccount(provider, data.preferred_username)
                        }}
                        onReject={(err) => {
                            console.log(err);
                        }}
                    >
                        <TwitchLoginButton />
                    </LoginSocialTwitch>
                );
            case 'Youtube':
                return (
                    <LoginSocialGoogle
                        client_id={process.env.REACT_APP_GG_APP_ID || '252171140139-shstg44ho0ls9ct9hvmtiltjqd8dfrrl.apps.googleusercontent.com'}
                        onLoginStart={onLoginStart}
                        redirect_uri={REDIRECT}
                        scope="openid profile email"
                        discoveryDocs="claims_supported"
                        access_type="offline"
                        onResolve={({ provider, data }) => {
                            console.log('provider', provider);
                            console.log('profile', profile);
                            setProvider(provider);
                            setProfile(data);
                        }}
                        onReject={err => {
                            console.log(err);
                        }}
                    >
                        <GoogleLoginButton />
                    </LoginSocialGoogle>
                );
            case 'Instagram':
                return (
                    <LoginSocialInstagram
                        client_id={process.env.REACT_APP_INSTAGRAM_APP_ID || '1071902213685727'}
                        client_secret={process.env.REACT_APP_INSTAGRAM_APP_SECRET || '610cf8088e5dbff5b6ea1fa998612799'}
                        redirect_uri={`${REDIRECT}`}
                        onLoginStart={onLoginStart}
                        onLogoutSuccess={onLogoutSuccess}
                        onResolve={({ provider, data }) => {
                            console.log('profile', data);
                            connectAccount(provider, data.username)
                        }}
                        onReject={(err) => {
                            console.log(err);
                        }}
                    >
                        <InstagramLoginButton />
                    </LoginSocialInstagram>
                );
        }
    }

    const disconnectAccount = (name) => {
        console.log(socialAccounts);
        const index = socialAccounts.findIndex(x => x.name == name);
        socialAccounts[index].username = null;
        socialAccounts[index].verified = false;
        socialAccounts[index].followers = 0;
        console.log(socialAccounts);
        setSocialAccounts([...socialAccounts]);
    }

    const getKey = () => {
        return Math.floor(Math.random() * 1000000)
    }

    const items = socialAccounts.map((sa, index) => (<Accordion.Item key={getKey()} eventKey={getKey()}>
        <Accordion.Header><span className="social-title">{sa.name}</span> {sa.username ? <><span className="social-user">{sa.username} <span>{sa.verified ? <><PersonBadgeFill /></> : <></>}</span></span> <Badge className="social-badge" bg="primary">{sa.followers}</Badge></> : <></>}</Accordion.Header>
        <Accordion.Body>
            {sa.username ?
                <Button variant="secondary" onClick={() => disconnectAccount(sa.name)}>Unlink</Button> :
                displaySocial(sa.name)
            }
        </Accordion.Body>
    </Accordion.Item>));


    return (
        <Accordion className="social-logins" >
            {items}
        </Accordion>
    );
}