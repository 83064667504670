import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { API } from "aws-amplify";
import { onError } from "../lib/errorLib";
import { s3Upload } from "../lib/awsLib";
import config from "../config";
import "./NewGift.css";
import Button from "react-bootstrap/esm/Button";

export default function NewGift() {
  const file = useRef(null);
  const nav = useNavigate();
  const [productUrl, setProductUrl] = useState("");
  const [productLoaded, setProductLoaded] = useState(false);
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return content.length > 0;
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
  }

  async function handleLoadProduct(e) {
    e.preventDefault();
    console.log('handleLoadProductNewGift', productUrl);
    setProductLoaded(true);
    
  }

  async function handleSubmit(event) {
    event.preventDefault();
  
    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }
    setIsLoading(true);
    try {
      const attachment = file.current ? await s3Upload(file.current) : null;
      await createGift({ content, attachment });
      nav("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  
  function createGift(gift) {
    return API.post("gifts", "/gifts", {
      body: gift,
    });
  }

  return (
    <div className="NewGift">

      <Form onSubmit={handleLoadProduct}>
        <Form.Group controlId="productUrl">
        <Form.Label title="Product Url">Product Link</Form.Label>
          <Form.Control
            value={productUrl}
            as="input"
            onChange={(e) => setProductUrl(e.target.value)}
          />
        </Form.Group>
        <Button
          type="submit"
        
        >Load Gift</Button>
      </Form>

      { productLoaded ? 
      <Form onSubmit={handleSubmit}>
        
        <Form.Group controlId="productUrl">
          <Form.Label title="Product Url">Product Link</Form.Label>
          <Form.Control
            value={productUrl}
            as="input"
            onChange={(e) => setProductUrl(e.target.value)}
          />
          </Form.Group>

        {/* <Form.Group controlId="content">
          <Form.Control
            value={content}
            as="textarea"
            onChange={(e) => setContent(e.target.value)}
          />
        </Form.Group> */}
        {/* <Form.Group controlId="file">
          <Form.Label>Attachment</Form.Label>
          <Form.Control onChange={handleFileChange} type="file" />
        </Form.Group> */}
        <LoaderButton
          block
          type="submit"
          size="lg"
          variant="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Create
        </LoaderButton>
      </Form> : <></> }
    </div>
  );
}