/* eslint-disable camelcase */
/**
 *
 * LoginSocialTwitch
 *
 */
 import { PASS_CORS_KEY } from './helper';
 import React, { memo, useCallback, useEffect } from 'react';

 
 const TWITCH_URL = 'https://id.twitch.tv';
 const TWITCH_API_URL = 'https://id.twitch.tv/oauth2';
 const PREVENT_CORS_URL = 'https://cors.bridged.cc';
 
 export const LoginSocialTwitch = ({
   state = '',
   scope = 'openid',
   client_id,
   client_secret,
   className = '',
   redirect_uri,
   allow_signup = false,
   isOnlyGetToken = false,
   isOnlyGetCode = false,
   children,
   onReject,
   onResolve,
   onLoginStart,
 }) => {
   useEffect(() => {
    const url = window.location.href.replace('dashboard#', 'dashboard?')
    console.log('url', url);
     const popupWindowURL = new URL(url);
     const code = popupWindowURL.searchParams.get('access_token');
     const state = popupWindowURL.searchParams.get('state');
     if (state?.includes('_twitch') && code) {
       localStorage.setItem('twitch', code);
       window.close();
     }
   }, []);
 
   const getProfile = useCallback(
     (data) => {
        console.log('getProfile')
       fetch(`${TWITCH_API_URL}/userinfo`, {
         method: 'GET',
         headers: {
            Authorization: `Bearer ${data.access_token}`,
         },
       })
         .then(res => res.json())
         .then((response) => {
           onResolve({ provider: 'twitch', data: { ...response, ...data } });
         })
         .catch(err => {
           onReject(err);
         });
     },
     [onReject, onResolve],
   );
 
   const getAccessToken = useCallback(
     (code) => {
       if (isOnlyGetCode) onResolve({ provider: 'twitch', data: { code } });
       else {
         const params = {
           code,
           state,
           redirect_uri,
           client_id,
           client_secret,
         };
         const headers = new Headers({
           'Content-Type': 'application/x-www-form-urlencoded',
           'x-cors-grida-api-key': PASS_CORS_KEY,
         });
 
         fetch(`${PREVENT_CORS_URL}/${TWITCH_URL}/oauth2/token`, {
           method: 'POST',
           headers,
           body: new URLSearchParams(params),
         })
           .then(response => response.text())
           .then(response => {
             const data = {};
             const searchParams = new URLSearchParams(response);
             for (const p of searchParams) {
               data[p[0]] = p[1];
             }
             if (data.access_token) {
               if (isOnlyGetToken) onResolve({ provider: 'twitch', data });
               else getProfile(data);
             } else onReject('no data');
           })
           .catch(err => {
             onReject(err);
           });
       }
     },
     [
       state,
       onReject,
       getProfile,
       onResolve,
       client_id,
       redirect_uri,
       client_secret,
       isOnlyGetCode,
       isOnlyGetToken,
     ],
   );
 
   const handlePostMessage = useCallback(
     async ({ type, code, provider }) => {
        console.log('handlePostMessage') 
        console.log('type', type);
        console.log('provider', provider)
        console.log('code', code);
        getProfile({ access_token: code} )
     },[getProfile],
   );
 
   const onChangeLocalStorage = useCallback(() => {
     window.removeEventListener('storage', onChangeLocalStorage, false);
     const code = localStorage.getItem('twitch');
     if (code) {
    console.log('onChangeLocalStorage')
       handlePostMessage({ provider: 'twitch', type: 'code', code });
       localStorage.removeItem('twitch');
     }
   }, [handlePostMessage]);
 
   const onLogin = useCallback(() => {
     onLoginStart && onLoginStart();
     window.addEventListener('storage', onChangeLocalStorage, false);
     const oauthUrl = `${TWITCH_URL}/oauth2/authorize?response_type=token&client_id=${client_id}&scope=${scope}&state=${
       state + '_twitch'
     }&redirect_uri=${redirect_uri}&allow_signup=${allow_signup}&response_type=`;
     const width = 450;
     const height = 730;
     const left = window.screen.width / 2 - width / 2;
     const top = window.screen.height / 2 - height / 2;
     window.open(
       oauthUrl,
       'Twitch',
       'menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=' +
         width +
         ', height=' +
         height +
         ', top=' +
         top +
         ', left=' +
         left,
     );
   }, [
     scope,
     state,
     client_id,
     redirect_uri,
     allow_signup,
     onLoginStart,
     onChangeLocalStorage,
   ]);
 
   return (
     <div className={className} onClick={onLogin}>
       {children}
     </div>
   );
 };
 
 export default memo(LoginSocialTwitch);